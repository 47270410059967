import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import style from './style.module.less'

// import TwitterIcon from '../../../static/assets/icons/twitter-icon.svg'
// import InstagramIcon from '../../../static/assets/icons/instagram-icon.svg'
// import FacebookIcon from '../../../static/assets/icons/facebook-icon.svg'

export default class Footer extends PureComponent {
	render() {
		return (
			<div className={style.FooterContainer}>
				<div className={style.FooterGrid}>
					<svg
						className={style.Logomark}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 60 40"
						width="60"
						height="40"
					>
						<rect
							x="4.8"
							y="4.5"
							transform="matrix(0.9877 -0.1564 0.1564 0.9877 -2.7593 4.9393)"
							style={{
								// fillRule: 'evenodd',
								// clipRule: 'evenodd',
								fill: '#000000',
							}}
							width="50.4"
							height="31"
						/>
						<rect
							x="15.1"
							y="14.5"
							transform="matrix(0.9877 -0.1565 0.1565 0.9877 -3.2716 2.9163)"
							style={{ fill: '#FFFFFF' }}
							width="3.7"
							height="15.5"
						/>
						<path
							style={{ fill: '#FFFFFF' }}
							d="M28.5,23c-0.2,0.1-0.5,0.1-0.8,0.2L26,23.5l0.8,5.1l-3.6,0.6l-2.4-15.3l5.7-0.9c2.2-0.4,4-0.2,5.2,0.4
c1.2,0.7,2,1.8,2.3,3.6c0.2,1.2,0.1,2.2-0.3,3c-0.4,0.8-1,1.5-1.9,2l4,5.2l-3.9,0.6L28.5,23z M27.5,20.4c1.1-0.2,1.8-0.5,2.2-0.9
c0.4-0.4,0.6-1.1,0.4-1.9c-0.1-0.8-0.5-1.4-1-1.7c-0.5-0.3-1.3-0.4-2.4-0.2l-2,0.3l0.8,4.8L27.5,20.4L27.5,20.4z"
						/>
						<polygon
							style={{ fill: '#FFFFFF' }}
							points="48.6,25.1 38.2,26.7 35.7,11.4 39.4,10.8 41.3,23.2 48.1,22.2 "
						/>
					</svg>
					<section className={style.TimeAndLocation}>
						<p className={style.TimeAndLocationParagraph}>
							April 10-11th, 2019 at{' '}
							<a
								href="http://thisisfr.ee/"
								target="_blank"
								rel="noreferrer noopener"
								style={{
									textDecoration: 'underline',
								}}
							>
								Free Studio
							</a>
						</p>
						<p className={style.TimeAndLocationParagraph}>
							68 Claremont St Toronto, ON.{' '}
							<Link
								to="/about/"
								style={{
									textDecoration: 'underline',
								}}
							>
								View Details
							</Link>
						</p>
					</section>
					<section className={style.PressInquiries}>
						<p className={style.PressInquiriesParagraph}>
							For press inquiries, contact
						</p>
						<p className={style.PressInquiriesParagraph}>
							<a
								href="mailto:publicrelations.ysdn2019@gmail.com"
								target="_top"
								style={{
									textDecoration: 'underline',
								}}
							>
								publicrelations.ysdn2019@gmail.com
							</a>
						</p>
					</section>
					<section className={style.SocialMedia}>
						<div>
							<a
								href="https://www.twitter.com/ysdn2019"
								target="_blank"
								rel="noreferrer noopener"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 101.01 101.01"
									className={style.SocialMediaImage}
								>
									<title>twitter-icon</title>
									<path
										fill="#000"
										d="M41.85,73.54c19.2,0,29.71-15.91,29.71-29.71,0-.45,0-.9,0-1.35a21.35,21.35,0,0,0,5.2-5.4,21.06,21.06,0,0,1-6,1.64,10.5,10.5,0,0,0,4.59-5.78,20.81,20.81,0,0,1-6.63,2.54A10.45,10.45,0,0,0,50.91,45,29.64,29.64,0,0,1,29.39,34.09,10.45,10.45,0,0,0,32.62,48a10.37,10.37,0,0,1-4.74-1.31v.13a10.46,10.46,0,0,0,8.38,10.24,10.46,10.46,0,0,1-4.72.18,10.47,10.47,0,0,0,9.76,7.25,21,21,0,0,1-13,4.48,22.77,22.77,0,0,1-2.48-.15,29.56,29.56,0,0,0,16,4.68"
									/>
									<circle
										style={{
											fill: 'none',
											stroke: '#000',
											strokeMiterlimit: '10',
											strokeWidth: '4px',
										}}
										cx="50.51"
										cy="50.51"
										r="48.51"
									/>
								</svg>
							</a>
						</div>
						<div>
							<a
								href="https://www.instagram.com/ysdn2019"
								target="_blank"
								rel="noreferrer noopener"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 101.01 101.01"
									className={style.SocialMediaImage}
								>
									<title>instagram-icon</title>
									<circle
										style={{
											fill: 'none',
											stroke: '#000',
											strokeMiterlimit: '10',
											strokeWidth: '4px',
										}}
										cx="50.51"
										cy="50.51"
										r="48.51"
									/>
									<path
										fill="#000"
										d="M74.35,50.53c0,3.14-.08,6.28-.17,9.42a16.11,16.11,0,0,1-1.9,7.81,12,12,0,0,1-8.72,6,43.5,43.5,0,0,1-6.35.51c-4.48.07-9,0-13.44,0a28.27,28.27,0,0,1-7.21-.68c-5.42-1.47-8.53-5.07-9.45-10.55a40.65,40.65,0,0,1-.41-5.84c-.06-4.5-.05-9,0-13.49A29.09,29.09,0,0,1,27.28,37c1.3-5.42,4.71-8.73,10.16-9.78a33.63,33.63,0,0,1,5.94-.51c4.73-.06,9.47,0,14.21,0a26.27,26.27,0,0,1,6.84.68c5.4,1.48,8.52,5.06,9.42,10.51a47.64,47.64,0,0,1,.43,6.21c.06,2.13,0,4.26,0,6.38ZM50.47,70v0c.68,0,1.37,0,2.06,0,3.17-.1,6.35-.08,9.51-.35a8,8,0,0,0,7.4-6.58,29.74,29.74,0,0,0,.48-6.45q.06-6.08,0-12.16c0-1.81-.12-3.63-.3-5.44A8.12,8.12,0,0,0,63,31.56a29.7,29.7,0,0,0-6.45-.48q-6.06-.06-12.1,0c-1.84,0-3.68.12-5.5.3A8.12,8.12,0,0,0,31.52,38,30,30,0,0,0,31,44.42c0,4.06-.05,8.13,0,12.2a47.06,47.06,0,0,0,.42,6.05,7.71,7.71,0,0,0,3.93,5.85,12,12,0,0,0,5.75,1.34C44.26,69.94,47.36,70,50.47,70Z"
									/>
									<path
										fill="#000"
										d="M62.75,50.53A12.27,12.27,0,1,1,50.53,38.25,12.32,12.32,0,0,1,62.75,50.53ZM50.51,42.61a7.91,7.91,0,1,0,7.88,7.92A7.94,7.94,0,0,0,50.51,42.61Z"
									/>
									<path d="M66,37.76A2.86,2.86,0,0,1,63.1,40.6a2.85,2.85,0,1,1,0-5.7A2.84,2.84,0,0,1,66,37.76Z" />
								</svg>
							</a>
						</div>
						<div>
							<a
								href="https://www.facebook.com/events/2218033058447423/"
								target="_blank"
								rel="noreferrer noopener"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 101.01 101.01"
									className={style.SocialMediaImage}
								>
									<title>facebook-icon</title>
									<circle
										style={{
											fill: 'none',
											stroke: '#000',
											strokeMiterlimit: '10',
											strokeWidth: '4px',
										}}
										cx="50.51"
										cy="50.51"
										r="48.51"
									/>
									<path
										id="f"
										fill="#000"
										d="M54.46,76V52.77h7.81l1.17-9.07h-9V37.92c0-2.62.73-4.41,4.48-4.41H63.7V25.42a62.33,62.33,0,0,0-7-.36c-6.91,0-11.66,4.22-11.66,12v6.66H37.31v9.08h7.78V76Z"
									/>
								</svg>
							</a>
						</div>
					</section>
				</div>
			</div>
		)
	}
}
