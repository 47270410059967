import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import style from './style.module.less'

export default class NavLink extends PureComponent {
	render() {
		const { name, id, color } = this.props

		return (
			<Link
				className={style.linkStyle}
				activeStyle={{
					fontWeight: 'bold',
					color: `${color}`,
					borderBottom: `1px solid ${color}`,
				}}
				to={id}
			>
				{name}
			</Link>
		)
	}
}

NavLink.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
}
