import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.less'
import NavGroup from './NavGroup'
import { Link } from 'gatsby'

export default class Navigation extends Component {
	constructor(props) {
		super(props)
		this.state = {
			width: 0,
			height: 0,
			isMenuOpen: false,
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
		this.toggleNavigation = this.toggleNavigation.bind(this)
	}

	componentDidMount() {
		this.updateWindowDimensions()

		window.addEventListener('resize', this.updateWindowDimensions)
		// window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions)
		// window.removeEventListener('scroll', this.handleScroll)
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight })
	}

	toggleNavigation() {
		this.setState({ isMenuOpen: !this.state.isMenuOpen })
	}

	render() {
		// console.log(this.props)
		// console.log(this.state)
		return (
			<div
				className={style.navContainer}
				style={{
					background:
						typeof this.props.backgroundColour === 'string' &&
						this.state.width < 768
							? this.props.backgroundColour
							: 'white',
					position:
						this.state.isMenuOpen === true ? 'fixed' : 'inherit',
					top: '0',
					left: '0',
				}}
			>
				<div
					className={style.navGrid}
					style={
						this.props.onHomepage && this.state.width < 768
							? {
								borderBottom: '0',
							  }
							: {
								borderBottom: '1px solid whitesmoke',
							  }
					}
				>
					<Link className={style.logomark} to={'/'}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 60 40"
							width="60"
							height="40"
						>
							<path
								style={
									this.props.onHomepage &&
									this.state.width < 768
										? {
											fillRule: 'evenodd',
											clipRule: 'evenodd',
											fill: 'white',
										  }
										: {
											fillRule: 'evenodd',
											clipRule: 'evenodd',
										  }
								}
								d="M30.1,17.6c0.2,0.8,0,1.5-0.4,1.9c-0.4,0.4-1.1,0.7-2.2,0.9l-2,0.4L24.7,16l2-0.3c1.1-0.2,1.9-0.1,2.4,0.2
						C29.6,16.2,30,16.8,30.1,17.6z M57.3,31.4L7.5,39.3L2.7,8.6l49.8-7.9L57.3,31.4z M20,29.6l-2.4-15.3l-3.7,0.6l2.4,15.3L20,29.6z
						 M35.8,27.2l-4-5.2c0.9-0.5,1.5-1.2,1.9-2c0.4-0.8,0.5-1.8,0.3-3c-0.3-1.8-1.1-2.9-2.3-3.6c-1.2-0.6-3-0.8-5.2-0.4l-5.7,0.9
						l2.4,15.3l3.6-0.6L26,23.5l1.7-0.3c0.3-0.1,0.6-0.1,0.8-0.2l3.4,4.8L35.8,27.2z M48.6,25.1l-0.5-2.9l-6.8,1l-1.9-12.4l-3.7,0.6
						l2.5,15.3L48.6,25.1z"
							/>
						</svg>
					</Link>
					<div // for desktop
						className={style.navGroup}
						style={
							this.state.width >= 768 ? {} : { display: 'none' }
						}
					>
						<NavGroup />
					</div>
					<div //for mobile
						className={style.navGroup}
						onClick={this.toggleNavigation}
						style={
							this.state.width < 768 ? {} : { display: 'none' }
						}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 32 32"
							width="32"
							height="32"
							style={{ paddingRight: '20px' }}
						>
							<title>Meatless Hamburger Menu</title>
							<line
								fill="none"
								stroke={
									this.props.onHomepage ? 'white' : 'black'
								}
								strokeWidth="2.5"
								strokeLinecap="round"
								strokeMiterlimit="10"
								x1="4.5"
								y1="11.5"
								x2="27.5"
								y2="11.5"
							/>
							<line
								fill="none"
								stroke={
									this.props.onHomepage ? 'white' : 'black'
								}
								strokeWidth="2.5"
								strokeLinecap="round"
								strokeMiterlimit="10"
								x1="4.5"
								y1="21.5"
								x2="27.5"
								y2="21.5"
							/>
						</svg>
					</div>
				</div>
				<div
					style={
						this.state.width < 768 && this.state.isMenuOpen === true
							? { zIndex: '10' }
							: {
								display: 'none',
								opacity: '0',
							  }
					}
				>
					<div className={style.MobileNavigationMenuWrapper}>
						<div
							style={{
								width: '87%',
								display: 'flex',
								justifyContent: 'flex-end',
								paddingTop: '32px',
								paddingBottom: '24px',
							}}
							onClick={this.toggleNavigation}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 32 32"
								width="32"
								height="32"
							>
								<line
									fill="none"
									stroke="#000000"
									strokeWidth="2.5"
									strokeLinecap="round"
									strokeMiterlimit="10"
									x1="7.87"
									y1="7.87"
									x2="24.13"
									y2="24.13"
								/>
								<line
									fill="none"
									stroke="#000000"
									strokeWidth="2.5"
									strokeLinecap="round"
									strokeMiterlimit="10"
									x1="7.87"
									y1="24.13"
									x2="24.13"
									y2="7.87"
								/>
							</svg>
						</div>
						<Link
							to="/"
							style={{
								fontSize: '24px',
							}}
							activeClassName="active"
							activeStyle={{
								fontWeight: 'bold',
								color: '#F85848',
							}}
						>
							Home
						</Link>
						<Link
							to="/about/"
							style={{
								fontSize: '24px',
							}}
							activeStyle={{
								fontWeight: 'bold',
								color: '#004AD1',
							}}
						>
							About
						</Link>
						<Link
							to="/work/"
							style={{
								fontSize: '24px',
							}}
							activeStyle={{
								fontWeight: 'bold',
								color: '#FFBA01',
							}}
						>
							Work
						</Link>
						<Link
							to="/graduates/"
							style={{
								fontSize: '24px',
							}}
							activeStyle={{
								fontWeight: 'bold',
								color: '#FF8000',
							}}
						>
							Graduates
						</Link>
						<Link
							to="/sponsors/"
							style={{
								fontSize: '24px',
							}}
							activeStyle={{
								fontWeight: 'bold',
								color: '#00DCCC',
							}}
						>
							Sponsors
						</Link>

						<section className={style.SocialMedia}>
							<div>
								<a
									href="https://www.twitter.com/ysdn2019"
									target="_blank"
									rel="noreferrer noopener"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 101.01 101.01"
										className={style.SocialMediaImage}
									>
										<title>twitter-icon</title>
										<path
											fill="#000"
											d="M41.85,73.54c19.2,0,29.71-15.91,29.71-29.71,0-.45,0-.9,0-1.35a21.35,21.35,0,0,0,5.2-5.4,21.06,21.06,0,0,1-6,1.64,10.5,10.5,0,0,0,4.59-5.78,20.81,20.81,0,0,1-6.63,2.54A10.45,10.45,0,0,0,50.91,45,29.64,29.64,0,0,1,29.39,34.09,10.45,10.45,0,0,0,32.62,48a10.37,10.37,0,0,1-4.74-1.31v.13a10.46,10.46,0,0,0,8.38,10.24,10.46,10.46,0,0,1-4.72.18,10.47,10.47,0,0,0,9.76,7.25,21,21,0,0,1-13,4.48,22.77,22.77,0,0,1-2.48-.15,29.56,29.56,0,0,0,16,4.68"
										/>
										<circle
											style={{
												fill: 'none',
												stroke: '#000',
												strokeMiterlimit: '10',
												strokeWidth: '4px',
											}}
											cx="50.51"
											cy="50.51"
											r="48.51"
										/>
									</svg>
								</a>
							</div>
							<div>
								<a
									href="https://www.instagram.com/ysdn2019"
									target="_blank"
									rel="noreferrer noopener"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 101.01 101.01"
										className={style.SocialMediaImage}
									>
										<title>instagram-icon</title>
										<circle
											style={{
												fill: 'none',
												stroke: '#000',
												strokeMiterlimit: '10',
												strokeWidth: '4px',
											}}
											cx="50.51"
											cy="50.51"
											r="48.51"
										/>
										<path
											fill="#000"
											d="M74.35,50.53c0,3.14-.08,6.28-.17,9.42a16.11,16.11,0,0,1-1.9,7.81,12,12,0,0,1-8.72,6,43.5,43.5,0,0,1-6.35.51c-4.48.07-9,0-13.44,0a28.27,28.27,0,0,1-7.21-.68c-5.42-1.47-8.53-5.07-9.45-10.55a40.65,40.65,0,0,1-.41-5.84c-.06-4.5-.05-9,0-13.49A29.09,29.09,0,0,1,27.28,37c1.3-5.42,4.71-8.73,10.16-9.78a33.63,33.63,0,0,1,5.94-.51c4.73-.06,9.47,0,14.21,0a26.27,26.27,0,0,1,6.84.68c5.4,1.48,8.52,5.06,9.42,10.51a47.64,47.64,0,0,1,.43,6.21c.06,2.13,0,4.26,0,6.38ZM50.47,70v0c.68,0,1.37,0,2.06,0,3.17-.1,6.35-.08,9.51-.35a8,8,0,0,0,7.4-6.58,29.74,29.74,0,0,0,.48-6.45q.06-6.08,0-12.16c0-1.81-.12-3.63-.3-5.44A8.12,8.12,0,0,0,63,31.56a29.7,29.7,0,0,0-6.45-.48q-6.06-.06-12.1,0c-1.84,0-3.68.12-5.5.3A8.12,8.12,0,0,0,31.52,38,30,30,0,0,0,31,44.42c0,4.06-.05,8.13,0,12.2a47.06,47.06,0,0,0,.42,6.05,7.71,7.71,0,0,0,3.93,5.85,12,12,0,0,0,5.75,1.34C44.26,69.94,47.36,70,50.47,70Z"
										/>
										<path
											fill="#000"
											d="M62.75,50.53A12.27,12.27,0,1,1,50.53,38.25,12.32,12.32,0,0,1,62.75,50.53ZM50.51,42.61a7.91,7.91,0,1,0,7.88,7.92A7.94,7.94,0,0,0,50.51,42.61Z"
										/>
										<path d="M66,37.76A2.86,2.86,0,0,1,63.1,40.6a2.85,2.85,0,1,1,0-5.7A2.84,2.84,0,0,1,66,37.76Z" />
									</svg>
								</a>
							</div>
							<div>
								<a
									href="https://www.facebook.com/events/2218033058447423/"
									target="_blank"
									rel="noreferrer noopener"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 101.01 101.01"
										className={style.SocialMediaImage}
									>
										<title>facebook-icon</title>
										<circle
											style={{
												fill: 'none',
												stroke: '#000',
												strokeMiterlimit: '10',
												strokeWidth: '4px',
											}}
											cx="50.51"
											cy="50.51"
											r="48.51"
										/>
										<path
											id="f"
											fill="#000"
											d="M54.46,76V52.77h7.81l1.17-9.07h-9V37.92c0-2.62.73-4.41,4.48-4.41H63.7V25.42a62.33,62.33,0,0,0-7-.36c-6.91,0-11.66,4.22-11.66,12v6.66H37.31v9.08h7.78V76Z"
										/>
									</svg>
								</a>
							</div>
						</section>
					</div>
				</div>
			</div>
		)
	}
}

Navigation.propTypes = {
	backgroundColour: PropTypes.string,
	onHomepage: PropTypes.bool,
}
