import React, { PureComponent } from 'react'
import NavLink from './NavLink'
import style from './style.module.less'

export default class NavGroup extends PureComponent {
	render() {
		const Links = [
			{ name: 'Home', color: '#F85848' },
			{ name: 'About', color: '#004FCF' },
			{ name: 'Work', color: '#FF8000' },
			{ name: 'Graduates', color: '#00DCCC' },
			{ name: 'Sponsors', color: '#FFBA01' },
		]

		const listItems = Links.map(link => (
			<NavLink
				key={link.name}
				name={link.name}
				id={
					link.name.toLowerCase() === 'home'
						? '/'
						: `/${link.name.toLowerCase()}/`
				}
				color={link.color}
			/>
		))
		return <div className={style.NavGroup}>{listItems}</div>
	}
}
