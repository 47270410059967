import React from 'react'
import { Link } from 'gatsby'
import SponsorStyles from '../components/sponsor-page-styles.module.less'

import Layout from '../components/layout'

// import RLSolutionsLogo from '../../static/assets/logos/rlSiteLogo_2x.png'
import FreeLogo from '../../static/assets/logos/free-logo.svg'
// import VeritivLogo from '../../static/assets/logos/veritiv-logo-main.png'
import VeritivLogo from '../../static/assets/logos/veritiv-logo.svg'
import UniverseLogo from '../../static/assets/logos/universe-logo.svg'
// import CriticalMassLogo from '../../static/assets/logos/logo-white-text.50cc2042.svg'
// import ForgeLogo from '../../static/assets/logos/logo.png'
// import WattLogo from '../../static/assets/logos/logo-1.png'
import YorkULogo from '../../static/assets/logos/Logo_York_University.svg'
import SheridanLogo from '../../static/assets/logos/Sheridan_College_2013_logo.svg'

// import SideStickers from '../../static/assets/side-stickers.svg'

const SponsorPage = () => (
	<Layout>
		<main className={SponsorStyles.wrapper}>
			<section className={SponsorStyles.grid}>
				<article className={SponsorStyles.thankYouBlock}>
					<h1 className={SponsorStyles.title}>
						Thank you to our generous sponsors.
					</h1>
				</article>
				<article className={SponsorStyles.blurbBlock}>
					<p className={SponsorStyles.blurb}>
						The work YSDN students put into the grad show wouldn’t
						have come to fruition without some wonderful people
						willing to back us up. Thank you to all our sponsors for
						their support in the IRL grad show.
					</p>
				</article>
				<article className={SponsorStyles.sponsorLogosBlock}>
					<img
						className={SponsorStyles.sponsorLogo}
						src={VeritivLogo}
						alt="Veritiv Logo"
					/>
					<img
						className={SponsorStyles.sponsorLogo}
						src={FreeLogo}
						alt="Free Logo"
					/>
					<img
						className={SponsorStyles.sponsorLogo}
						// style={{ margin: '60px', transform: 'scale(2)' }}
						style={{ minWidth: '250px' }}
						src={UniverseLogo}
						alt="Universe Logo"
					/>
					<img
						className={SponsorStyles.sponsorLogo}
						src={YorkULogo}
						alt="York University Logo"
					/>
					<img
						className={SponsorStyles.sponsorLogo}
						src={SheridanLogo}
						alt="Sheridan College Logo"
					/>
				</article>
				<div className={SponsorStyles.comeMeetTheCastBackground} />
				<div
					className={SponsorStyles.comeMeetTheCastBackgroundBottom}
				/>
				<article className={SponsorStyles.likeWhatYouSeeBlock}>
					<h3 className={SponsorStyles.comeMeetTheCastHeader}>
						Like What You See?
					</h3>
					<p className={SponsorStyles.comeMeetTheCastParagraph}>
						Meet the grads behind these projects In Real Life at the
						grad show on April 10 & 11 at Free Space!
					</p>
					<Link
						className={SponsorStyles.comeMeetTheCastLink}
						to="/about/"
					>
						View Details
					</Link>
				</article>
			</section>
		</main>
	</Layout>
)

export default SponsorPage
