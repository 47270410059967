import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Navigation from './Navigation'
import Footer from './Footer'

import style from './layout.module.less'

const Layout = ({ children, backgroundColour, onHomepage }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={data => (
			<>
				<Helmet
					title={data.site.siteMetadata.title}
					meta={[
						{ name: 'description', content: 'Sample' },
						{ name: 'keywords', content: 'sample, something' },
					]}
				>
					<html lang="en" />
				</Helmet>
				<div className={style.container}>
					<Navigation
						onHomepage={onHomepage}
						backgroundColour={backgroundColour}
					/>
					{children}
					<Footer />
				</div>
			</>
		)}
	/>
)

Layout.propTypes = {
	children: PropTypes.any,
	backgroundColour: PropTypes.string,
	onHomepage: PropTypes.bool,
}

export default Layout
